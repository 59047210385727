<template>
  <div>
    <!--头部-->
    <el-row :gutter="2">
      <el-col :xs="2" :sm="6" :md="6" :lg="5" :xl="5">
        <div class="grid-content"/>
      </el-col>
      <el-col :xs="20" :sm="12" :md="12" :lg="14" :xl="14">
        <div class="text-center box-head">
          <img class="logo" :src="websiteData.logo" alt="">
        </div>
        <div class="text-center box-head">
          <div class="row text-center">
            <div class="col-6 col-md-4 col-lg-3 col-xl-2">
              <div class="flex-colum-acenter m-b-20" @click="toResetSearch">
                <img class="ent-img" src="@/assets/all.jpg" alt="">
                <div class="ent-title flex-row-acenter pointer">
                  <span :class="{selected:selected===-2 }">TODOS</span>
                  <img v-if="selected !== -2" class="icon-search" src="@/assets/search.svg" alt="">
                  <img v-if="selected === -2" class="icon-search" src="@/assets/search-checked.svg" alt="">
                </div>
              </div>
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-xl-2">
              <div class="flex-colum-acenter m-b-20" @click="toNew">
                <img class="ent-img" src="@/assets/new.png" alt="">
                <div class="ent-title flex-row-acenter pointer">
                  <span :class="{selected:selected===-1 }">NUEVO</span>
                  <img v-if="selected !== -1" class="icon-search" src="@/assets/search.svg" alt="">
                  <img v-if="selected === -1" class="icon-search" src="@/assets/search-checked.svg" alt="">
                </div>
              </div>
            </div>
            <div v-for="(item, index) in classesList" :key="index" class="col-6 col-md-4 col-lg-3 col-xl-2">
              <div class="flex-colum-acenter m-b-20" @click="toClasses(item.id,index)">
                <img class="ent-img" :src="baseUrlImg + item.img +'?imageMogr2/thumbnail/50x'" alt="">
                <div class="ent-title flex-row-acenter pointer">
                  <span :class="{selected:selected===index }">{{ item.title }}</span>
                  <img v-if="selected !== index" class="icon-search" src="@/assets/search.svg" alt="">
                  <img v-if="selected === index" class="icon-search" src="@/assets/search-checked.svg" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :xs="2" :sm="6" :md="6" :lg="5" :xl="5">
        <div class="grid-content"/>
      </el-col>
    </el-row>

    <!--商品列表-->
    <!--搜索-->
    <el-row :gutter="2">
      <el-col :xs="2" :sm="8" :md="8" :lg="8" :xl="8">
        <div class="grid-content"/>
      </el-col>
      <el-col :xs="20" :sm="8" :md="8" :lg="8" :xl="8">
        <div class="text-center flex-row">
          <el-input size="medium" :placeholder="placeholder" v-model="form.title" class="input-with-select">
            <el-button size="medium" slot="append" class="el-icon-search" @click="toSearch"></el-button>
          </el-input>
          <el-button size="medium" @click="toResetSearch" type="danger">Atrás (退一步）</el-button>
        </div>
      </el-col>
      <el-col :xs="2" :sm="8" :md="8" :lg="8" :xl="8">
        <div class="grid-content"/>
      </el-col>
    </el-row>

    <el-row :gutter="2">
      <el-col :xs="2" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="grid-content"/>
      </el-col>
      <el-col :xs="20" :sm="12" :md="12" :lg="12" :xl="12">
        <el-alert
            class="message-item"
            v-if="websiteData.message"
            style="margin-top: 10px;"
            title="Mensaje(信息)"
            type="warning"
            :description="websiteData.message"
            :closable="true"
            show-icon>
        </el-alert>
      </el-col>
      <el-col :xs="2" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="grid-content"/>
      </el-col>
    </el-row>
    <el-row :gutter="0" class="goods-box">
      <el-col :xs="1" :sm="3" :md="3" :lg="3" :xl="3">
        <div class="grid-content"/>
      </el-col>
      <el-col :xs="22" :sm="18" :md="18" :lg="18" :xl="18">
        <div class="text-center">
          <el-row justify="start" :gutter="20">
            <div class="box-goods row">
              <div class="el-col-24">
                <div class="row text-center">
                  <div v-for="(item, index) in goodsList" :key="index"
                       class="el-col-12 el-col-md-8 el-col-lg-4  el-col-xl-3">
                    <div class="flex-colum-acenter m-b-20" @click="drawerGoodsOpen(item)">
                      <div class="goods-item flex-colum-acenter">
                        <div>
                          <el-image class="goods-img" :src="baseUrlImg + item.master + '?imageMogr2/thumbnail/160x'" alt="" lazy ></el-image>
                          <div v-if="item.status === 3" class="disabled-overlay">
                            <span class="disabled-text">AGOTADO</span>
                          </div>
                        </div>

                        <div class="goods-money flex-row-jsb">
                              <span class="tag sort">
                                {{ item.number }}
                              </span>
                          <span class="tag money">
                                <span class="prefix">￥</span>{{ item.min_price }}
                              </span>
                        </div>
                        <div class="goods-name">
                              <span class="name">
                                {{ item.title }}
                              </span>
                        </div>
                        <div class="goods-cart flex-row-jsb">
                          <div class="cart-item">
                            <div class="flex-row-acenter">
                              <div class="tag" @click="onClickNum(1)">-</div>
                              <input name="KeyWord" class="form-control input-btn" type="text" :value="count">
                              <div class="tag" @click="onClickNum(2)">+</div>
                            </div>
                          </div>
                          <div class="cart-group">
                            <div class="cart-btn flex-row-ajcenter">
                              <img class="icon-cart" src="@/assets/cart.png" alt="">
                              <span class="add">加入</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-row>
        </div>
      </el-col>
      <el-col :xs="1" :sm="3" :md="3" :lg="3" :xl="3">
        <div class="grid-content"/>
      </el-col>
    </el-row>

    <!--分页-->
    <div v-if="total > 0" class="box-footer text-center">
      <div class="footer-item">
        <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="100"
            :pager-count="5"
            @current-change="handleSizeChange"/>
      </div>
    </div>

    <!--购物车按钮-->
    <el-button type="primary" class="float-button" @click="drawerCartOpen">
      <el-badge :value="cartCount" class="item">
        <i class="el-icon-shopping-cart-full"></i>
      </el-badge>
    </el-button>

    <!--商品信息-->
    <div class="goods-item">
      <el-dialog title="Informacion del producto（商品信息）" :fullscreen="true" :visible.sync="drawerGoods"
                 :close-on-click-modal="false"
                 @close="channel">
        <div class="goods-spec-box">
          <div class="goods-content">
            <div class="box-goods">
              <el-image
                  class="goods-img"
                  :src="baseUrlImg + goodsInfo.master"
                  @click="getImage(baseUrlImg + goodsInfo.master)"
                  :preview-src-list="srcList">
              </el-image>

              <!--<img class="goods-img" :src="baseUrlImg + goodsInfo.master" alt="">-->
              <div class="goods-info flex-column">
                <div class="goods-code">{{ goodsInfo.number }}</div>
                <p class="goods-title">{{ goodsInfo.title }}</p>
                <div class="goods-price"><span class="attr">￥</span>{{ checkedGoods.spec.price }}</div>

                <div class="goods-spec">
                  <div class="spec-item">
                    <div class="spec-item flex-row-wrap">
                      <div v-for="(item, index) in goodsInfo.price_text" :key="index">
                        <div class="spec-title pointer" :class="item.checked ? 'checked' : ''"
                             @click="checkedSpec(index)">
                          {{ item.title }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div slot="footer" class="dialog-footer">
            <el-alert
                style="margin-bottom: 10px;"
                :title="title"
                :closable="false"
                type="warning">
            </el-alert>
            <div class="flex-row-jsb flex-row-acenter">
              <div class="goods-num flex-row-acenter">
                <div class="flex-colum">
                  <span class="num-title">Cantidad</span>
                  <span class="num-title">数量</span>
                </div>
                <div class="tag-item flex-row-acenter">
                  <div class="tag" @click="onClickNum(1)">-</div>
                  <input name="KeyWord" class="form-control input-btn" type="text" @change="numInput"
                         v-model="checkedGoods.spec.num_init">
                  <div class="tag" @click="onClickNum(2)">+</div>
                </div>
              </div>
              <div class="goods-btn">
                <el-button class="btn-channel" @click="channel()">
                  <div>Cancelar</div>
                  <div class="btn-attr">取消</div>
                </el-button>
                <el-button class="btn-primary" type="primary" @click="save()">
                  <div>Agregar</div>
                  <div class="btn-attr">加入</div>
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>

    <cart
        v-if="drawerCart"
        :drawerShow="drawerCart"
        :cartList="cartList"
        @cartCount="cartCountEvent"
        @drawerEvent="eventCart"
        @resetCart="resetCart"
    ></cart>

    <!--输入密码蒙版-->
    <div class="paw-item">
      <el-dialog
          title="Verificación 解锁验证"
          :visible.sync="centerDialogVisible"
          width="30%"
          :fullscreen="true"
          :show-close="false"
          :close-on-press-escape="false"
          :close-on-click-modal="false"
          center>
        <div class="content-box">
          <el-input v-model="query.password" placeholder="Ingrese contraseña 请输入解锁码"></el-input>
        </div>

        <div class="dialog-footer-paw">
          <el-button class="btn-item" type="primary" @click="getLogin()">Confirmar 确 认</el-button>
        </div>
      </el-dialog>
      <el-dialog
          title="温馨提示"
          :visible.sync="statusDialogVisible"
          width="30%"
          :fullscreen="true"
          :show-close="false"
          :close-on-press-escape="false"
          :close-on-click-modal="false"
          center>
        <div class="content-box">
          {{websiteData.desc}}
        </div>
      </el-dialog>
<!--      <div style="color: #ffffff;position: absolute;bottom: 50px;z-index: 99999;text-align: center;width: 100%;">-->
<!--        <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2024084876号-2</a>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>

import Cart from "@/views/index/components/cart.vue";
import {getClassesList, getGoodsList, getWebsite, pullLock} from "@/api";
import {BASE_URL_IMG} from "../../config";

export default {
  name: 'App',
  components: {Cart},
  data() {
    return {
      websiteData: {
        logo: '',
        message: '',
      },
      classesList: [],
      goodsList: [],
      count: 1,
      placeholder: 'Código o nombre',
      drawerGoods: false,
      drawerCart: false,
      form: {
        title: '',
        page: 1,
        class_id: '',
        title_number: ''
      },
      query: {
        password: ''
      },
      total: 0,
      baseUrlImg: BASE_URL_IMG,
      goodsInfo: {},
      checkedGoods: {
        spec: {},
        info: {},
        checked: false
      },
      cartList: [],
      cartCount: 0,
      title: '',
      centerDialogVisible: true,
      statusDialogVisible: false,
      selected: -2,
      srcList: []
    };
  },
  created() {
    this.getWebsite()
    this.getGoodsList()
    this.getClassesList()
    // localStorage.clear()
    const cartCount = JSON.parse(localStorage.getItem('cart'))
    if (cartCount) {
      this.cartCount = cartCount.length
    }
    console.log(this.$isLogin)

    const isLogin = sessionStorage.getItem('isLogin')
    if (isLogin) {
      this.centerDialogVisible = false
    }

  },

  methods: {
    getImage(url) {
      this.srcList = []
      this.srcList.push(url)
    },
    getLogin() {
      const password = this.query.password
      if (!password) {
        this.$message.warning('请输入密码')
        return false
      }
      pullLock({key: password}).then((res) => {
        if (res.code === 1) {
          console.log(res)
          // this.$store.commit('setIsLogin',true)
          sessionStorage.setItem('isLogin', true)
          this.centerDialogVisible = false
        } else {
          this.$message.error('密码错误')
        }

      })
    },
    numInput(e) {
      if (e.target.value % this.checkedGoods.spec.num === 0) {
        this.checkedGoods.spec.num_init = e.target.value
      } else {
        this.checkedGoods.spec.num_init = this.checkedGoods.spec.num
        this.$message.warning('购买数量必须为' + this.checkedGoods.spec.num + '的整数倍数')
      }
      this.checkedGoods = JSON.parse(JSON.stringify(this.checkedGoods))
    },
    getWebsite() {
      getWebsite().then(res => {
        console.log(res)
        this.websiteData = res.data
        if (res.data.status === 0) {
           this.statusDialogVisible=true
          return
        }
        if (res.data.logo) {
          this.websiteData.logo = this.baseUrlImg + res.data.logo
        }
      })
    },
    getGoodsList() {
      getGoodsList(this.form).then(res => {
        this.goodsList = res.data.data
        this.total = res.data.total
      })
    },
    getClassesList() {
      getClassesList().then(res => {
        this.classesList = res.data
      })
    },
    toSearch() {
      delete this.form.class_id
      this.selected = -2
      // delete this.form.type
      // delete this.form.title
      // delete this.form.title_number
      this.getGoodsList()
    },
    toResetSearch() {
      this.form = {}
      this.selected = -2
      this.getGoodsList()
    },
    toNew() {
      this.selected = -1
      delete this.form.class_id
      this.form.type = 1
      this.getGoodsList()
    },
    toClasses(class_id, index) {
      this.form = {
        title: '',
        page: 1,
        class_id: class_id,
        title_number: ''
      },
          this.selected = index
      this.getGoodsList()
    },
    handleSizeChange(data) {
      // console.log(data)
      document.documentElement.scrollTop = 0;
      this.form.page = data
      this.getGoodsList()
    },
    onClickNum(index) {
      const init = parseInt(this.checkedGoods.spec.num_init);
      const num = parseInt(this.checkedGoods.spec.num)
      if (index === 1) {
        if (this.checkedGoods.spec.num_init <= this.checkedGoods.spec.num) {
          let msg = '最小数量不能小于' + this.checkedGoods.spec.num_init
          this.$confirm(msg, '提示', {
            confirmButtonText: '知道了',
            showCancelButton: false,
            type: 'warning'
          }).then(() => {
          }).catch(() => {
          })
          return
        }
        this.checkedGoods.spec.num_init = init - num;
      } else {
        this.checkedGoods.spec.num_init = num + init;
      }
      this.checkedGoods = JSON.parse(JSON.stringify(this.checkedGoods))
    },
    toSelectEnt() {
      this.$message('点击了搜索!');
      return
    },
    drawerGoodsOpen(row) {
      if (row.status === 3) {
        // 缺货
        return
      }

      row.price_text.forEach((item, index) => {
        item.checked = false
        if (index === 0) {
          item.checked = true
        }
      })
      this.goodsInfo = JSON.parse(JSON.stringify(row))
      if (row.price_text.length > 0) {
        this.checkedGoods.spec = this.goodsInfo.price_text[0]
        this.checkedGoods.spec.num_init = this.goodsInfo.price_text[0].num
        this.checkedGoods.info = this.goodsInfo
        this.checkedGoods.checked = false
        this.title = '最低购买数量' + parseInt(this.goodsInfo.price_text[0].num) + ',请按' + parseInt(this.goodsInfo.price_text[0].num) + '的倍数购买'
      }
      this.drawerGoods = true
    },
    drawerCartOpen() {
      this.cartList = JSON.parse(localStorage.getItem('cart'))
      this.drawerCart = true
    },
    cartCountEvent(v) {
      this.cartCount = v
    },
    eventCart() {
      this.drawerCart = false
    },
    resetCart(v) {
      this.cartCount = v
    },
    save() {

      const _that = this
      // console.log('selecct:', _that.checkedGoods)
      if (_that.checkedGoods.spec.num_init % _that.checkedGoods.spec.num !== 0) {
        return
      }
      let cart = localStorage.getItem('cart');
      // console.log('cart:', cart)
      // return;
      if (!cart || cart === 'undefined') {
        // console.log('购物车为空')
        let goods = []
        goods.push(_that.checkedGoods)
        localStorage.setItem('cart', JSON.stringify(goods))
      } else {
        cart = JSON.parse(cart)
        // console.log('购物车:', cart)
        let inAarray = 0
        cart.forEach(function (item, index, cart) {
          if (_that.checkedGoods.info.id === item.info.id && _that.checkedGoods.spec.title === item.spec.title) {
            cart[index].spec.num_init = parseInt((_that.checkedGoods.spec.num_init)) + parseInt(item.spec.num_init)
            inAarray = 1
          }
        })

        if (inAarray === 0) {
          cart.push(_that.checkedGoods)
        }
        // console.log('结果:', cart)
        localStorage.setItem('cart', JSON.stringify(cart))
      }
      this.cartCount = JSON.parse(localStorage.getItem('cart')).length
      this.drawerGoods = false
    },
    checkedSpec(index) {
      let info = []
      this.goodsInfo.price_text.forEach((item, key) => {
        item.checked = key === index
        item.num_init = item.num
        info.push({
          "checked": item.checked,
          "title": item.title,
          "unit_no": item.unit_no,
          "price": item.price,
          "num": parseInt(item.num),
          "num_init": parseInt(item.num),
        })

        if (item.checked) {
          this.checkedGoods = {
            info: this.goodsInfo,
            spec: item,
            checked: false
          }
          this.title = '最低购买数量' + parseInt(item.num) + ',请按' + parseInt(item.num) + '的倍数购买'
        }
      })
      this.goodsInfo.price_text = JSON.parse(JSON.stringify(info))
    },
    channel() {
      this.drawerGoods = false
    }
  },
}
</script>

<style lang="scss" scoped>
@import "index.scss";

.selected {
  color: red;
}

</style>
